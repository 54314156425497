
import Vue from "vue";
import Component from "vue-class-component";
import Quote from "@/components/Quote.vue";

@Component({
  components: {Quote},
  props: {
    msg: String,
  },
})
export default class Landing extends Vue {
  // Data
  msg!: string;
  buttonColor = "rgba(248, 237, 223, 0.9)";
  currentCarouselSlide = 0;
  values: string[] = [
    "Streven naar een positief zelfbeeld en meer zelfvertrouwen",
    "Ieder kind is uniek, met zijn/haar sterktes en zwaktes",
    "Werken aan een mooie toekomst op maat van ieder kind",
  ]

  // Interface methods

  public navigateToContactPage() {
    this.$router.push({path: "/contact"})
  }

  public navigateToVacancyPage() {
    this.$router.push({path: "/vacature"})
  }
}
