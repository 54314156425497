
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  props: {
    msg: String,
  },
})
export default class HelloWorld extends Vue {
  msg!: string;
}
