
import {Component, Vue} from "vue-property-decorator";
import SpeechTherapyService from "@/components/SpeechTherapyService.vue";

@Component({
  components: {SpeechTherapyService}
})
export default class STLearningDisabilities extends Vue {
  // Data

  // Lifecycle

  // Interface methods
  // Getters & setters
  // Helper methods
}

