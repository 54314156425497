
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ProfileOnHome extends Vue {
  // Data
  @Prop({required: true}) aboutUsId!: string
  @Prop({default: ''}) photoUrl!: string
  @Prop({default: ''}) name!: string
  @Prop({default: ''}) job!: string
  @Prop({default: 'center'}) photoPosition!: string;

  public get photoAsCssBackground() {
    // eslint-disable-next-line
    // return `background-image: url(${$images['example.png']});background-position: ${this.photoPosition}`
    // return `background-image: url("${require(this.photoUrl)}");background-position: ${this.photoPosition}`;
    return `background-image: url("${this.photoUrl}");background-position: ${this.photoPosition}`;
  }

  // Lifecycle


  // Interface methods

  backgroundStyle() {
    // Use a computed property to dynamically generate the background image style
    return {
      backgroundImage: `background-image: url(${require(`@/assets/public${this.photoUrl}`)});background-position: ${this.photoPosition}`
    };
  }

  // Getters & setters

  navigateToPerson(id: string) {
    const href = `/over-ons#${id}`;
    this.$router.push({path: href})
  }

  // Helper methods
}
