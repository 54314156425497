
import { Component, Vue } from "vue-property-decorator";
import InConstruction from "@/components/InConstruction.vue"; // @ is an alias to /src

@Component({
  components: {
    InConstruction,
  },
})
export default class InConstructionView extends Vue {}
