import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
import HomeView from "../views/HomeView.vue";
import InConstructionView from "@/views/InConstructionView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/over-ons",
        name: "about",
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
    },
    {
        path: "/vacature",
        name: "vacature",
        component: () => import(/* webpackChunkName: "vacature" */ "../views/VacatureView.vue"),
    },
    {
        path: "/logopedie",
        name: "speechTherapy",
        component: () => import(/* webpackChunkName: "speechTherapy" */ "../views/SpeechTherapyView.vue"),
    },
    {
        path: "/psychologie",
        name: "psychology",
        component: () => import(/* webpackChunkName: "Psychology" */ "../views/PsychologyView.vue"),
    },
    {
        path: "/huiswerk-begeleiding",
        name: "homeworkAssistance",
        component: () => import(/* webpackChunkName: "homeworkAssistance" */ "../views/HomeworkAssistanceView.vue"),
    },
    {
        path: "/typ10",
        name: "Typ10",
        component: () => import(/* webpackChunkName: "typ10" */ "../views/Typ10View.vue"),
    },
    {
        path: "/tarieven-en-afspraken",
        name: "PricesAndAgreements",
        component: () => import(/* webpackChunkName: "pricesAndAgreements" */ "../views/PricesAndAgreementsView.vue"),
    }, {
        path: "/voice-analyser",
        name: "Voice analyser",
        component: () => import(/* webpackChunkName: "voiceAnalysis" */ "../views/VoiceAnalyserView.vue"),
    },
    {
        path: "/construction",
        name: "home",
        component: InConstructionView,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
