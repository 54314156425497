
import {Component, Prop, Vue} from "vue-property-decorator";
import {INavItem} from "@/models/models";

@Component
export default class Navbar extends Vue {
  @Prop({required: true}) public readonly appName!: string;
  group = null;
  drawer = false;
  fontFamily = 'Josefin Slab';

  navItems: INavItem[] = [
    {name: "Logopedie", routeName: "logopedie"},
    {name: "Psychologie", routeName: "psychologie"},
    // {name: "Huiswerkbegeleiding", routeName: "huiswerk-begeleiding"},
    // {name: "Typ10", routeName: "typ10"},
    {name: "Tarieven & afspraken", routeName: "tarieven-en-afspraken"},
    {name: "Over ons", routeName: "over-ons"},
    {name: "Contact", routeName: "contact"},
    {name: "Vacatures", routeName: "vacature"},
  ];

  public get isMobile() {
    return !this.$vuetify.breakpoint.mdAndUp;
  }
}
