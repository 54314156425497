
import {Component, Vue} from "vue-property-decorator";
import InConstruction from "@/components/InConstruction.vue";
import Landing from "@/components/Landing.vue";
import STArticulationDisabilities from "@/components/STArticulationDisabilities.vue";
import ServicesOnHome from "@/components/ServicesOnHome.vue";
import WhenToContactSpeechTherapistsOnHome from "@/components/WhenToContactSpeachTherapistsOnHome.vue";
import OurTeamOnHome from "@/components/OurTeamOnHome.vue";

@Component({
  components: {
    OurTeamOnHome,
    ServicesOnHome,
    STArticulationDisabilities,
    Landing,
    InConstruction,
    WhenToContactSpeechTherapistsOnHome
  },
})
export default class HomeView extends Vue {
}
