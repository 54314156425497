
import {Component, Vue} from "vue-property-decorator";
import ProfileOnHome from "@/components/ProfileOnHome.vue";
import {imageNames} from "@/assets/ImageNames";

@Component({
  computed: {
    imageNames() {
      return imageNames
    }
  },
  components: {
    ProfileOnHome
  }
})
export default class OurTeamOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods

  navigateToPerson(id: string) {
    console.log(id)
    this.$router.push({path: `/over-ons`})
  }

  // Getters & setters
  // Helper methods
}
