
import Vue from "vue";
import Component from "vue-class-component";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

@Component({components: {Footer, Navbar, Header: Navbar}})
export default class App extends Vue {
  appName = "Praktijk KeiTof!";
}
