
import {Component, Vue} from "vue-property-decorator";

@Component
export default class ServicesOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods

  navigateToSpeachTherapyPage() {
    this.$router.push({path: "/logopedie"})
  }

  // Getters & setters
  // Helper methods
}
