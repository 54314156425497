
import {Component, Vue} from "vue-property-decorator";

@Component
export default class WhenToContactSpeechTherapistsOnHome extends Vue {
  // Data

  // Lifecycle

  // Interface methods
  // Getters & setters
  // Helper methods
}
